import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
    // Create a global lightbox controller
    const lightboxController = {
        items: [],
        currentIndex: 0,
        isOpen: false,
        container: null,

        next() {
            this.currentIndex += 1;
            if (this.currentIndex > this.items.length - 1) {
                this.currentIndex = 0;
            }
            this.updateContent();
        },

        prev() {
            this.currentIndex -= 1;
            if (this.currentIndex == -1) {
                this.currentIndex = this.items.length - 1;
            }
            this.updateContent();
        },

        close() {
            this.isOpen = false;
            document.body.style.overflow = "";
            if (this.container) {
                this.container.style.display = "none";
                this.container.setAttribute("aria-hidden", "true");
            }
        },

        open(items, index = 0) {
            this.items = items;
            this.currentIndex = index;
            this.isOpen = true;
            document.body.style.overflow = "hidden";

            if (this.container) {
                this.container.style.display = "block";
                this.container.setAttribute("aria-hidden", "false");
                this.updateContent();
            }
        },

        updateContent() {
            if (!this.container) return;

            const currentItem = this.items[this.currentIndex];
            if (!currentItem) return;

            // Update navigation buttons visibility
            const prevBtn = this.container.querySelector(".alpine-lightbox__nav--prev");
            const nextBtn = this.container.querySelector(".alpine-lightbox__nav--next");

            if (prevBtn) {
                prevBtn.setAttribute("tabindex", this.currentIndex > 0 ? "0" : "-1");
            }

            if (nextBtn) {
                nextBtn.setAttribute("tabindex", this.currentIndex < this.items.length - 1 ? "0" : "-1");
            }

            // Update content
            const imageContainer = this.container.querySelector(".alpine-lightbox__image-container");
            const htmlContainer = this.container.querySelector(".alpine-lightbox__html");

            // Update the lightbox title for screen readers
            this.container.setAttribute("aria-label", `Image ${this.currentIndex + 1} of ${this.items.length}`);

            if (currentItem.type === "image") {
                if (imageContainer) {
                    const img = imageContainer.querySelector("img");
                    if (img) {
                        img.src = currentItem.content;
                        img.alt = currentItem.alt || "";
                    }
                    imageContainer.style.display = "block";
                    imageContainer.setAttribute("aria-hidden", "false");
                }
                if (htmlContainer) {
                    htmlContainer.style.display = "none";
                    htmlContainer.setAttribute("aria-hidden", "true");
                }
            } else {
                if (htmlContainer) {
                    htmlContainer.innerHTML = currentItem.content;
                    htmlContainer.style.display = "block";
                    htmlContainer.setAttribute("aria-hidden", "false");
                }
                if (imageContainer) {
                    imageContainer.style.display = "none";
                    imageContainer.setAttribute("aria-hidden", "true");
                }
            }

            // Update navigation dots
            this.updateNavigationDots();
        },

        updateNavigationDots() {
            const dotsContainer = this.container.querySelector(".alpine-lightbox__dots");
            if (!dotsContainer) return;

            // Clear existing dots
            dotsContainer.innerHTML = "";

            // If there's only one item, don't show the dots
            if (this.items.length <= 1) return;

            // Create new dots
            this.items.forEach((_, index) => {
                const dot = document.createElement("button");
                dot.className = `alpine-lightbox__dot ${index === this.currentIndex ? "active" : ""}`;
                dot.setAttribute("aria-label", `Go to item ${index + 1}`);
                dot.setAttribute("role", "tab");
                dot.setAttribute("aria-selected", index === this.currentIndex ? "true" : "false");
                dot.setAttribute("tabindex", index === this.currentIndex ? "0" : "-1");
                dot.setAttribute("id", `lightbox-dot-${index}`);
                dot.addEventListener("click", e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.currentIndex = index;
                    this.updateContent();
                });
                dotsContainer.appendChild(dot);
            });
        },

        init() {
            this.createContainer();
        },

        createContainer() {
            let container = document.querySelector(".alpine-lightbox");
            if (!container) {
                container = document.createElement("div");
                container.className = "alpine-lightbox";
                container.setAttribute("role", "dialog");
                container.setAttribute("aria-modal", "true");
                container.setAttribute("aria-label", "Image lightbox");
                container.setAttribute("aria-hidden", "true");
                container.style.display = "none";
                container.innerHTML = `
                    <div class="alpine-lightbox__overlay"></div>
                    <div class="alpine-lightbox__content" role="document">
                        <button class="alpine-lightbox__close" aria-label="Close lightbox">&times;</button>
                        <button class="alpine-lightbox__nav alpine-lightbox__nav--prev" aria-label="Previous item">&lt;</button>
                        <div class="alpine-lightbox__container">
                            <div class="alpine-lightbox__image-container">
                                <img class="alpine-lightbox__image" src="" alt="" />
                            </div>
                            <div class="alpine-lightbox__html" role="region" aria-live="polite"></div>
                        </div>
                        <button class="alpine-lightbox__nav alpine-lightbox__nav--next" aria-label="Next item">&gt;</button>
                        <div class="alpine-lightbox__dots" role="tablist" aria-label="Image navigation"></div>
                    </div>
                `;
                document.body.appendChild(container);

                // Add event listeners
                const closeBtn = container.querySelector(".alpine-lightbox__close");
                const overlay = container.querySelector(".alpine-lightbox__overlay");
                const prevBtn = container.querySelector(".alpine-lightbox__nav--prev");
                const nextBtn = container.querySelector(".alpine-lightbox__nav--next");

                if (closeBtn) {
                    closeBtn.addEventListener("click", () => this.close());
                }

                if (overlay) {
                    overlay.addEventListener("click", () => this.close());
                }

                // Add click event listener to the content container to close when clicking outside content
                const contentContainer = container.querySelector(".alpine-lightbox__container");
                if (contentContainer) {
                    contentContainer.addEventListener("click", e => {
                        // Only close if clicking directly on the content container (not its children)
                        if (e.target === contentContainer) {
                            e.preventDefault();
                            e.stopPropagation();
                            this.close();
                        }
                    });
                }

                if (prevBtn) {
                    prevBtn.addEventListener("click", e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.prev();
                    });
                }

                if (nextBtn) {
                    nextBtn.addEventListener("click", e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.next();
                    });
                }

                // Add keyboard navigation
                document.addEventListener("keydown", e => {
                    if (!this.isOpen) return;

                    if (e.key === "Escape") {
                        this.close();
                    } else if (e.key === "ArrowRight") {
                        e.preventDefault();
                        this.next();
                    } else if (e.key === "ArrowLeft") {
                        e.preventDefault();
                        this.prev();
                    }
                });
            }

            this.container = container;
        }
    };

    // Initialize the lightbox
    lightboxController.init();

    // Register the directive
    Alpine.directive("lightbox", (el, { modifiers }) => {
        let groupName = modifiers[0] || "default";

        // Check if this is a standalone image (direct img tag with x-lightbox)
        const isStandalone = el.tagName === "IMG" && !modifiers.length;

        // Find all elements in the same group
        const getGroupItems = () => {
            // If it's a standalone image, just return this element as a single item
            if (isStandalone) {
                return [
                    {
                        type: "image",
                        content: el.getAttribute("src"),
                        alt: el.getAttribute("alt") || ""
                    }
                ];
            }

            // Get all elements in the same group, checking both modifiers and data attributes
            const selector = groupName === "default" ? "[x-lightbox]:not([x-lightbox\\.]), [x-lightbox]:not([data-group])" : `[x-lightbox\\.${groupName}], [x-lightbox][data-group="${groupName}"]`;

            const groupElements = document.querySelectorAll(selector);
            const items = [];

            groupElements.forEach(element => {
                // For images
                if (element.tagName === "IMG") {
                    items.push({
                        type: "image",
                        content: element.getAttribute("src"),
                        alt: element.getAttribute("alt") || ""
                    });
                }
                // For HTML content
                else {
                    // Clone the element to get its content
                    const clone = element.cloneNode(true);
                    // Remove the x-lightbox attribute and data-group to prevent recursion
                    clone.removeAttribute("x-lightbox");
                    clone.removeAttribute("data-group");
                    items.push({
                        type: "html",
                        content: clone.innerHTML
                    });
                }
            });

            return items;
        };

        // Handle click on the element
        const clickHandler = e => {
            e.preventDefault();
            e.stopPropagation();

            // Set group and update items
            if (groupName !== "default" && !isStandalone) {
                el.setAttribute("data-group", groupName);
            }

            const items = getGroupItems();

            // For standalone images, always use index 0
            let index = isStandalone ? 0 : null;

            // Only calculate index for grouped items
            if (index === null) {
                const selector = groupName === "default" ? "[x-lightbox]:not([x-lightbox\\.]), [x-lightbox]:not([data-group])" : `[x-lightbox\\.${groupName}], [x-lightbox][data-group="${groupName}"]`;

                index = Array.from(document.querySelectorAll(selector)).indexOf(el);
            }

            // Open lightbox
            lightboxController.open(items, index);
        };

        // Add click event listener
        el.addEventListener("click", clickHandler);

        // Set initial group if not default
        if (groupName !== "default" && !isStandalone) {
            el.setAttribute("data-group", groupName);
        }

        // Add a class to indicate the element has a lightbox
        el.classList.add("has-lightbox");
        el.classList.add("x-lightbox");

        // Add accessibility attributes
        el.setAttribute("role", "button");
        el.setAttribute("aria-haspopup", "dialog");
        el.setAttribute("tabindex", "0");

        // For standalone images, add cursor pointer style
        if (isStandalone) {
            el.style.cursor = "pointer";
        }

        // Cleanup
        return () => {
            el.removeEventListener("click", clickHandler);
        };
    });
});

/**
 * Alpine.js Lightbox Directive Documentation
 * ==========================================
 *
 * The x-lightbox directive provides a simple way to create lightbox functionality
 * for images and HTML content.
 *
 * IMPORTANT: x-lightbox will only work when wrapped with an x-data directive.
 *
 * Basic Usage:
 * ------------
 *
 * 1. For a single image:
 *    <div x-data>
 *      <img x-lightbox src="path/to/image.jpg" alt="Image description" />
 *    </div>
 *
 * 2. For a group of images (will be navigable):
 *    <div x-data>
 *      <img x-lightbox.group1 src="path/to/image1.jpg" alt="Image 1" />
 *      <img x-lightbox.group1 src="path/to/image2.jpg" alt="Image 2" />
 *      <img x-lightbox.group1 src="path/to/image3.jpg" alt="Image 3" />
 *    </div>
 *
 * 3. For HTML content:
 *    <div x-data>
 *      <div x-lightbox>
 *        <h2>Custom HTML Content</h2>
 *        <p>This content will be displayed in the lightbox.</p>
 *      </div>
 *    </div>
 *
 * Features:
 * ---------
 *
 * - Keyboard navigation: Use arrow keys to navigate between items and Escape to close
 * - Click outside content to close the lightbox
 * - Navigation dots for multiple items
 * - Responsive design
 * - Support for both images and HTML content
 * - Accessibility features (ARIA attributes, keyboard navigation, screen reader support)
 *
 * Accessibility:
 * -------------
 *
 * The lightbox includes the following accessibility features:
 * - ARIA attributes for screen reader support
 * - Keyboard navigation (arrow keys, Escape, Tab)
 * - Focus management
 * - Proper labeling of controls
 * - Semantic HTML structure
 *
 * CSS Classes:
 * ------------
 *
 * The following class is added to elements with the x-lightbox directive:
 * - 'has-lightbox': Added to all elements with the x-lightbox directive
 * */
